import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSystemLogs(ctx, params) {
      return useJwt.fetchSystemLogs(params).then(response => response);
    },
    getSystemLogDetail(ctx, id) {
      return useJwt.getSystemLogDetail(id).then(response => response);
    },
    reRunSystemLog(ctx, id) {
      return useJwt.reRunSystemLog(id).then(response => response);
    },
  },
};
